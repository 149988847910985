<template>
  <div class="container-content-base">
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout>
      <v-layout v-if="detail" column>
        <v-layout>
          <v-layout column>
            <v-layout align-center>
              <label class="text-h6-1">
                {{ $t("way_bill") }}:
                <span class="font-weight-bold">
                  {{ detail.deliveryCode }}
                </span>
              </label>
              <v-chip
                :color="getColorChip"
                :class="`${getClassTextByStatus} ml-2 text-subtitle-1 font-weight-large`"
              >
                {{ detail.statusDescription }}
              </v-chip>
            </v-layout>
            <v-layout>
              <label>
                {{ $t("shop_name") }}:
                <span class="primary--text text-h6">{{ detail.shopName }}</span>
              </label>
            </v-layout>
          </v-layout>
          <v-layout column>
            <v-layout justify-end>
              <v-btn
                v-if="isShowActionEdit && checkRoleBtnEdit"
                color="primary"
                outlined
                class="mr-4"
                @click="gotoOrderUpdate"
              >
                <v-icon size="20" class="mr-2">mdi-pencil</v-icon>
                {{ $t("update_order") }}
              </v-btn>
              <v-btn
                v-if="isShowAction && checkRoleBtn"
                color="primary"
                outlined
                class="mr-4"
                @click="onShowConfirmDel"
              >
                <v-icon size="20" class="mr-2">mdi-close-box-outline</v-icon>
                {{ $t("cancel_order") }}
              </v-btn>
              <v-btn
                v-if="isShowAction"
                color="primary"
                @click="onViewWayBillNewTab"
              >
                <v-icon size="20" class="mr-2">mdi-printer-outline</v-icon>
                {{ $t("print_bill_of_lading") }}
              </v-btn>
            </v-layout>
            <v-layout v-if="detail.updatedByName && !isShowCancelBy" justify-end>
              <label>
                {{ $t("edit-by") }}:
                {{ detail.updatedByName ? getTextTruncate(detail.updatedByName,30) + " - " : "" }}
              </label>
              <label class="ml-2">
                {{ $t("edit-time") }}:
                {{ formatDateTimeHn(detail.updatedAt) }}
              </label>
            </v-layout>
          </v-layout>
          <v-layout v-if="isShowCancelBy" justify-end column>
            <v-layout justify-end>
              <label>
                {{ $t("cancelled_by") }}:
                {{ detail.cancelBy ? getTextTruncate(detail.cancelBy,30) : "" }}
              </label>
            </v-layout>
            <v-layout justify-end>
              <label>
                {{ $t("cancelled_time") }}:
                {{ formatDateTimeHn(detail.updatedAt) }}
              </label>
            </v-layout>
          </v-layout>
        </v-layout>
        <v-layout>
          <v-layout column class="border-primary mr-2 rounded-lg pb-2">
            <v-layout column>
              <v-card-title class="text-h5 font-weight-bold pb-5">
                {{ $t("order_info") }}
              </v-card-title>
            </v-layout>
            <v-layout column>
              <v-card-title class="pt-0 text-subtitle-1 font-weight-bold">
                {{ $t("info-sender") }}
              </v-card-title>
              <v-card-subtitle class="py-0 text-body-2">
                {{ detail.senderName }}
              </v-card-subtitle>
              <v-card-subtitle class="py-0 text-body-2">
                {{ detail.senderPhone }}
              </v-card-subtitle>
              <v-card-text class="blur--text text-caption">
                {{ detail.senderAddress }}
              </v-card-text>
            </v-layout>
            <v-layout column>
              <v-card-title class="pt-0 text-subtitle-1 font-weight-bold">
                {{ $t("info-receiver") }}
              </v-card-title>
              <v-card-subtitle class="py-0 text-body-2">
                {{ detail.receiverName }}
              </v-card-subtitle>
              <v-card-subtitle class="py-0 text-body-2">
                {{ detail.receiverPhone }}
              </v-card-subtitle>
              <v-card-text class="blur--text text-caption">
                {{ `${detail.receiverAddress}, ${detail.receiverCommuneName}, ${detail.receiverDistrictName}, ${detail.receiverProvinceName}` }}
              </v-card-text>
            </v-layout>

            <!--Thong tin van chuyen -->
            <v-card-title class="py-0 text-subtitle-1 font-weight-bold">{{
              $t("info-shipping")
            }}</v-card-title>
            <v-layout>
              <v-layout column class="col-6 pa-0">
                <v-card-text class="blur--text text-body-2 py-0">
                  {{ $t("group-shipping") }}
                </v-card-text>
                <v-card-text class="pt-0 text-subtitle-2">
                  {{ detail.partnerName }}
                </v-card-text>
              </v-layout>
              <v-layout column>
                <v-card-text class="blur--text text-body-2 py-0">
                  {{ $t("shipping_service") }}
                </v-card-text>
                <v-card-text class="pt-0 text-subtitle-2">
                  {{ detail.serviceName }}
                </v-card-text>
              </v-layout>
            </v-layout>
            <v-layout>
              <v-layout column class="col-6 pa-0">
                <v-card-text class="blur--text text-body-2 py-0">
                  {{ $t("shipping_method") }}
                </v-card-text>
                <v-card-text class="pt-0 text-subtitle-2">
                  {{ getTextTransportType(detail) }}
                </v-card-text>
              </v-layout>
              <v-layout v-if="isShowPickupTime" column>
                <v-card-text class="blur--text text-body-2 py-0">
                  {{ $t("appointment_time") }}
                </v-card-text>
                <v-card-text v-if="detail.partnerId === constants.partnerGroup.ninjaVan" class="pt-0 text-subtitle-2">
                  {{
                    formatPickUpDateTime(detail.pickupTimeFrom, detail.pickupTimeTo)
                  }}
                </v-card-text>
                <v-card-text v-else class="pt-0 text-subtitle-2">
                  {{ formatDate(detail.pickDate) }} -
                  {{
                    getTextWorkShip(detail.pickWorkShift)
                  }}
                </v-card-text>
              </v-layout>
            </v-layout>

            <!--Thong tin don hang-->
            <v-card-title class="py-0 text-subtitle-1 font-weight-bold">{{
              $t("order_info")
            }}</v-card-title>
            <v-layout v-if="detail.suePackages && detail.suePackages.length" column>
              <div v-for="(item, index) in detail.suePackages" :key="index">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    {{ item.orderNo }}
                  </v-list-item-avatar>
                  <v-layout class="d-flex" style="justify-content: space-between">
                    <v-list-item-content>
                      <v-list-item-subtitle class="text-subtitle-2">{{ item.code }}</v-list-item-subtitle>
                      <v-list-item-title class="font-detail-order">{{ item.description }}</v-list-item-title>
                      <v-list-item-subtitle class="d-flex">
                        <div class="text-subtitle-2">
                          {{ `${item.length}cm x ${item.width}cm x ${item.height}cm` }}, {{ formatDecimal(item.weight) }}kg
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <div class="font-detail-order text-right" style="font-weight: bold">{{ `${formatNumber(item.orderValue)}` }}</div>
                    </v-list-item-content>
                  </v-layout>
                </v-list-item>
                <v-divider />
              </div>
              <div class="d-flex">
                <v-layout column class="col-6 pa-0 mt-1">
                  <v-card-text class="blur--text text-body-2 py-0">
                    {{ $t("order_weight") }}
                  </v-card-text>
                  <v-card-text class="pt-0 font-detail-order">
                    <span :class="hasNewWeight ? 'text-decoration-line-through mr-2' : ''">{{ formatDecimal(detail.weight) }}</span>
                    <span v-if="hasNewWeight">({{ formatDecimal(detail.newWeight) }})</span> kg
                  </v-card-text>
                </v-layout>
                <v-layout column class="col-6 pa-0 mt-1" align-end>
                  <div class="blur--text text-body-2 py-0 mr-4">
                    {{ $t("order_value") }}
                  </div>
                  <div class="pt-0 font-detail-order mr-4">
                    {{ formatNumber(detail.orderValue) }}
                  </div>
                </v-layout>
              </div>
              <v-layout>
                <v-layout column>
                  <v-card-text class="blur--text text-body-2 py-0">
                    {{ $t("pays_the_shipping_fee") }}
                  </v-card-text>
                  <v-card-text class="pt-0 text-subtitle-2">
                    {{
                      detail.paymentBy === serviceFeePayersConst.sender
                        ? this.$t("sender")
                        : this.$t("receiver")
                    }}
                  </v-card-text>
                </v-layout>
                <v-layout column>
                  <v-card-text class="blur--text text-body-2 py-0">
                    {{ $t("cod") }}
                  </v-card-text>
                  <v-card-text class="pt-0 text-subtitle-2">
                    {{ !isNaN(detail.codValue) ? formatDecimalView(detail.codValue) : "" }}
                  </v-card-text>
                </v-layout>
              </v-layout>
            </v-layout>
            <v-layout v-else column>
              <v-layout column>
                <v-card-text class="blur--text text-body-2 py-0">
                  {{ $t("dest-product") }}
                </v-card-text>
                <v-card-text class="pt-0 text-subtitle-2">
                  {{ detail.description }}
                </v-card-text>
              </v-layout>
              <v-layout column>
                <v-card-text class="py-0 text-body-2">
                  <span class="blur--text text-body-2">
                    {{ $t("size") }}
                    <v-icon>mdi-compare-horizontal</v-icon>
                    {{ $t("tranfer_weight") }}:
                  </span>
                  {{ formatDecimal(detail.estimateWeight) }} kg
                </v-card-text>
                <v-card-text class="pt-0 text-subtitle-2">
                  {{
                    `${detail.length}cm x ${detail.width}cm x ${detail.height}cm`
                  }}
                </v-card-text>
              </v-layout>
              <v-layout>
                <v-layout column class="col-6 pa-0">
                  <v-card-text class="blur--text text-body-2 py-0">
                    {{ $t("order_weight") }}
                  </v-card-text>
                  <v-card-text class="pt-0 text-subtitle-2">
                    <span :class="hasNewWeight ? 'text-decoration-line-through mr-2' : ''">{{ formatDecimal(detail.weight) }}</span>
                    <span v-if="hasNewWeight">({{ formatDecimal(detail.newWeight) }})</span> kg
                  </v-card-text>
                </v-layout>
                <v-layout column>
                  <v-card-text class="blur--text text-body-2 py-0">
                    {{ $t("cod") }}
                  </v-card-text>
                  <v-card-text class="pt-0 text-subtitle-2">
                    {{ !isNaN(detail.codValue) ? formatDecimalView(detail.codValue) : "" }}
                  </v-card-text>
                </v-layout>
              </v-layout>
              <v-layout>
                <v-layout column class="col-6 pa-0">
                  <v-card-text class="blur--text text-body-2 py-0">
                    {{ $t("order_value") }}
                  </v-card-text>
                  <v-card-text class="pt-0 text-subtitle-2">
                    {{ formatNumber(detail.orderValue) }}
                  </v-card-text>
                </v-layout>
                <v-layout column>
                  <v-card-text class="blur--text text-body-2 py-0">
                    {{ $t("pays_the_shipping_fee") }}
                  </v-card-text>
                  <v-card-text class="pt-0 text-subtitle-2">
                    {{
                      detail.paymentBy === serviceFeePayersConst.sender
                        ? this.$t("sender")
                        : this.$t("receiver")
                    }}
                  </v-card-text>
                </v-layout>
              </v-layout>
            </v-layout>
            <!--ghi chu giao hang-->
            <v-layout v-if="detail.partnerId === constants.partnerGroup.ninjaVan" column>
              <v-card-text class="blur--text text-body-2 py-0">
                {{ $t("note_order") }}
              </v-card-text>
              <v-card-text class="pt-0 text-subtitle-2">
                {{ detail.note }}
              </v-card-text>
            </v-layout>

            <!--Dich vu-->
            <v-layout v-if="detail.tags !== ''">
              <v-card-text class="blur--text text-body-2 py-0">
                {{ $t("establish-service") }}
              </v-card-text>
            </v-layout>
            <v-layout column class="ml-2">
              <div v-for="(item, idx) in detail.orderFeeDtos" :key="idx" class="d-flex mr-4 ml-2 text-subtitle-2 justify-space-between">
                <span> {{ item.name }} </span>
                <span> {{ item.amount !== 0 ? formatDecimal(item.amount) : $t("free_price") }} </span>
              </div>
            </v-layout>
            <!---->
            <v-layout justify-space-between class="mt-2">
              <v-card-text class="blur--text text-body-2 py-0" style="color: #858C94">
                {{ $t("fee_total") }} ({{ $t("vnd_unit") }})
              </v-card-text>
              <v-card-text class="py-0 text-subtitle-2 text-right">
                {{ formatDecimal(detail.serviceFeesValue) }}
              </v-card-text>
            </v-layout>
            <v-layout justify-space-between>
              <v-card-text class="blur--text text-body-2 py-0" style="color: #858C94">
                {{ $t("fee_delivery") }} ({{ $t("vnd_unit") }})
              </v-card-text>
              <v-card-text class="py-0 text-subtitle-2 text-right">
                {{ formatDecimal(detail.shipValue) }}
              </v-card-text>
            </v-layout>
            <v-layout justify-space-between class="mt-1">
              <v-card-text class="text-subtitle-2 py-0">
                {{ $t("total_fee-service") }}
              </v-card-text>
              <v-card-text class="py-0 text-subtitle-2 text-right">
                {{ getTotalFee }}
              </v-card-text>
            </v-layout>
            <v-layout justify-space-between class="border rounded-lg mx-4">
              <v-card-text class="d-flex py-3" style="align-items: center; font-weight: bold">
                <img
                  src="@/assets/icons/ic-cash.svg"
                >
                {{ $t("total_revenue") }} ({{ $t("vnd_unit") }})
              </v-card-text>
              <v-card-text class="py-3 text-right text-h5">
                {{ formatDecimal(detail.totalValue) }}
              </v-card-text>
            </v-layout>
          </v-layout>

          <v-layout
            column
            class="
              border-primary
              col-6
              ml-2
              rounded-lg
              pa-0
              pb-2
              max-height-tracking
              overflow-y-auto
            "
            justify-start
            align-start
          >
            <v-layout column justify-start align-start>
              <v-card-title class="pb-0 text-h5 font-weight-bold">
                {{ $t("order_tracking") }}
              </v-card-title>
              <v-stepper
                v-if="trackingItems.length > 0"
                v-model="trackingItems.length"
                vertical
                class="box-shadow-none pl-22"
              >
                <template v-for="(item, index) in trackingItems">
                  <v-stepper-step
                    :key="index"
                    complete
                    :step="index + 1"
                    class="pos-rel"
                  >
                    <v-layout column class="pos-abs posl-n17">
                      {{ formatDate(item.dateTime) }}
                      <small class="text-body-2">
                        {{ formatTimeHm(item.dateTime) }}
                      </small>
                    </v-layout>
                    {{ item.text }}
                    <small class="text-body-2">
                      {{ item.reason }}
                    </small>
                  </v-stepper-step>
                  <v-stepper-content
                    :key="`sc-${index}`"
                    :step="stepActive"
                    class="py-4"
                  />
                </template>
              </v-stepper>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>

      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :icon-src="getIcon"
        :message="message"
      />

      <!-- dialog confirm del -->
      <dialog-confirm
        v-model="showConfirmDel"
        :icon-src="getIcon"
        :message="message"
        :text-cancel="textCancel"
        :text-accept="textAccept"
        :on-cancel="onCancelConfirmDel"
        :on-accept="onAcceptConfirmDel"
      />
      <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import { mapActions } from 'vuex'
import { DataBreadCrumbUtils } from '@/helpers/dataBreadCrumbUtils'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import { OrderService } from "@/services/orderService";
import constants from "@/constants";
import mixinStatusOrder from "./mixins/mixinStatusOrder"
import { StringUtils } from "@/helpers/stringUtils";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { ReportService } from "@/services/reportService";
import { StorageService } from "@/services/storageService";
import routePaths from "@/router/routePaths";
export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirm,
    DialogConfirmTokenExpried,
    Breadscrumbs
  },
  mixins: [mixinStatusOrder],
  data() {
    return {
      constants,
      transportTypeConst: constants.transportType,
      serviceFeePayersConst: constants.serviceFeePayers,
      id: 0,
      detail: null,
      trackingItems: [],
      stepActive: 0,

      // notifications
      showNoti: false,
      typeNoti: constants.typeAlert.warning,
      message: "",

      showLoading: false,

      showConfirmDel: false,
      textCancel: this.$t("cancel"),
      textAccept: this.$t("cancel_order"),

      showConfirmTokenExpried: false,
      lstFeeTag: [],
      ArrayTag: [],
      listFeeTagMap: [],
      arrayListFee: [],
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      breadcrumbs: [
        {
          text: '',
          disabled: false,
          isActive: false,
          href: ''
        },
        {
          text: 'order_detail',
          disabled: true,
          isActive: true,
        },
      ]
    };
  },
  computed: {
    checkRoleBtnEdit() {
      return (this.currentRole.includes("ROLE_ADMIN") || this.currentRole.includes("ROLE_CUSTOMER_CARE")) ||
      !this.currentRole.includes("ROLE_SALE") || this.currentRole.includes("ROLE_ACCOUNTANT")
    },
    checkRoleBtn() {
      return (this.currentRole.includes("ROLE_ADMIN") || this.currentRole.includes("ROLE_CUSTOMER_CARE")) ||
      this.currentRole.includes("ROLE_SALE") || !this.currentRole.includes("ROLE_ACCOUNTANT")
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    getColorChip() {
      if (!this.detail) return "";

      return StringUtils.getColorByStatus(this.detail.status);
    },
    getClassTextByStatus() {
      if (!this.detail) return "";

      return StringUtils.getClassTextByStatus(this.detail.status);
    },
    transportTypeText() {
      if (!this.detail) return "";

      return StringUtils.getIconSrc(this.detail.transportType);
    },
    isShowPickupTime() {
      return (
        this.detail &&
        this.detail.transportType === constants.transportType.ship
      );
    },
    isShowActionEdit() {
      return (
        this.detail &&
        (this.detail.status === constants.orderStatus.waiting ||
          this.detail.status === constants.orderStatus.pickUpFailed ||
          this.detail.status === constants.orderStatus.completed ||
          this.detail.status === constants.orderStatus.delivering ||
          this.detail.status === constants.orderStatus.waitingDelivery ||
          this.detail.status === constants.orderStatus.refundingToSender ||
          this.detail.status === constants.orderStatus.failDelivery)
      );
    },
    isShowAction() {
      return (
        this.detail && this.detail.status === constants.orderStatus.waiting
      );
    },
    isShowCancelBy() {
      return (
        this.detail && this.detail.status === constants.orderStatus.cancelled
      );
    },
    getTotalFee() {
      let total = 0;
      total = this.detail.serviceFeesValue + this.detail.shipValue;
      return this.formatDecimal(total);
    },
    getTextTrackingID() {
      let tracking = "";
      tracking = this.detail.deliveryCode.replace('SSV', '')
      return tracking
    },
    hasNewWeight() {
      return this.detail.newWeight > 0
    },
  },
  created() {
    if (window.location.pathname && this.currentRole.includes("ROLE_ACCOUNTANT")) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.id = parseInt(this.$route.query.id);
    this.initData();
  },
  methods: {
    ...mapActions('layout', ['setBreadcrumbs']),
    async initData() {
      await this.getDetail();
      if (this.detail.status === constants.orderStatus.failDelivery) {
        this.breadcrumbs[0].text = "order_list_process";
        this.breadcrumbs[0].href = routePaths.LIST_ORDER_PROCESS
      }
      if (this.detail.returnPartPackage) {
        this.breadcrumbs[0].text = "order_list_partnal";
        this.breadcrumbs[0].href = routePaths.LIST_ORDER_ONE_PART
      }
      if (this.detail.status !== 10 && !this.detail.returnPartPackage) {
        this.breadcrumbs[0].text = "order_list";
        this.breadcrumbs[0].href = routePaths.ORDERS
      }
    },
    async getDetail() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await OrderService.getDetail(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.detail = data;
        if (this.detail.tags !== undefined && this.detail.tags) {
          this.ArrayTag = this.detail.tags.split(",")
        }
        this.trackingItems = this.parseTracking(data);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
        setTimeout(() => {
          this.$router.go(-1);
        }, constants.timeOutRole);
      }
    },
    getTextPartner(item) {
      if (item.partnerId === constants.partnerGroup.ninjaVan) {
        return this.$t("ninja-van")
      }
      return this.$t("ghtk")
    },
    getTextTransportType(item) {
      if (item.transportType === "INDIVIDUAL") {
        return this.$t("self_delivery")
      }
      return this.$t("shipper_delivery")
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    },
    getTextWorkShip(item) {
      if (item === constants.pickWorkShip.morning) {
        return this.$t("morning")
      } else if (item === constants.pickWorkShip.afternoon) {
        return this.$t("afternoon")
      } else {
        return this.$t("night")
      }
    },
    bindRequestPars() {
      const pars = {
        ordersId: this.id,
      };
      return pars;
    },
    parseTracking(data) {
      const result = [];
      if (data.trackingHistory && data.trackingHistory.length > 0) {
        data.trackingHistory.forEach((item) => {
          if (item.receiveDate) {
            const val = {
              dateTime: item.receiveDate,
              text: this.getTextHookDescription(
                item.hookStatus,
                item.hookDescription
              ),
              reason: item.reason ? item.reason : ''
            };

            result.push(val);
          }
        });
      }
      this.stepActive = result.length;
      const resultSort = this.sortedItems(result);
      return resultSort;
    },
    sortedItems(arrData) {
      arrData.sort((a, b) => {
        const dtNumA = DateTimeUtils.formatDateTime(
          a.dateTime,
          constants.formatDateTimeCompare
        );
        const dtNumB = DateTimeUtils.formatDateTime(
          b.dateTime,
          constants.formatDateTimeCompare
        );
        return dtNumB - dtNumA;
      });
      return arrData;
    },
    getTextHookDescription(status, des) {
      if (status.toLowerCase() === "cancelled") {
        return des.substr(0, 6);
      } else {
        return des;
      }
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    formatDate(val) {
      return val ? DateTimeUtils.formatDateTime(val, constants.formatDate) : '';
    },
    formatDateTimeHn(val) {
      return val ? DateTimeUtils.formatDateTime(val, constants.formatDateTimeHm) : '';
    },
    formatTimeHm(val) {
      return val ? DateTimeUtils.formatDateTime(val, constants.formatTimeHm) : '';
    },
    formatDecimal(val) {
      return val !== undefined ? StringUtils.formatDecimal(val) : ''
    },
    formatNumber(val) {
      return val ? StringUtils.formatNumber(val) : '';
    },
    formatDecimalView(val) {
      if (typeof val === "number") {
        return val !== undefined ? StringUtils.formatDecimal(val) : '';
      } else {
        return val;
      }
    },
    formatPickUpDateTime(from, to) {
      return DateTimeUtils.formatPickUpDateTime(from, to);
    },
    onShowConfirmDel() {
      this.message = this.$t("cancel_this_orders");
      this.typeNoti = constants.typeAlert.warning;
      this.showConfirmDel = true;
    },
    onCancelConfirmDel() {
      this.showConfirmDel = false;
      this.message = "";
    },
    async onAcceptConfirmDel() {
      this.showConfirmDel = false;
      const item = this.detail;
      if (item !== null && item.id > 0) {
        this.showLoading = true;
        const pars = this.bindRequestOrdersCancel(item);
        const { status, data } = await OrderService.cancel(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          await this.getDetail();
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        }
      }
    },
    bindRequestOrdersCancel(item) {
      const ids = [];
      ids.push(item.id);
      const pars = {
        ordersIds: ids,
      };

      return pars;
    },
    async onViewWayBillNewTab() {
      this.showLoading = true;
      const item = this.detail;
      const pars = this.bindRequestOrdersCancel(item);
      const { status, data } = await ReportService.wayBill(pars);
      if (status === constants.statusCode.ok) {
        await this.downloadFile(data.url);
        this.showLoading = false;
      } else {
        this.showLoading = false;
        this.toggleDialogNoti({ state: true, msg: data.message });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
      }
    },
    async downloadFile(url) {
      const { status, data } = await ReportService.reportFiles(url);
      if (status === constants.statusCode.ok) {
        var fileUrl = URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        window.open(fileUrl, "_blank");
      }
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive)
      this.setBreadcrumbs(breadCrumbs)
    },
    gotoOrderUpdate() {
      if (this.detail.suePackages && this.detail.suePackages.length) {
        this.$router.push({
          path: routePaths.ORDER_MULTIBOX_UPDATE,
          query: { id: this.id },
        });
      } else {
        this.$router.push({
          path: routePaths.ORDER_UPDATE,
          query: { id: this.id },
        });
        this.addBreadCrumbs(routePaths.ORDER_UPDATE)
      }
    },
  },
};
</script>
<style src="@/styles/postOffice.scss" lang="scss">
</style>

